// Main javascript entry point
// Should handle bootstrapping/starting application

'use strict';

//Import jQuery
import $ from 'jquery';

//Import Lightbox
import 'lightbox2'

//Import Foundation
import 'foundation';


$(document).foundation();
